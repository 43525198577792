import * as React from 'react'
import { navigate } from 'gatsby'

import SEO from '../modules/seo'
import { Typography } from '../fragments'

import '../styles/404.scss'

const NotFoundPage = () => {
  const goHomeHandler = () => {
    navigate('/')
  }

  React.useEffect(() => {
    window.addEventListener('keypress', goHomeHandler)
    return () => window.removeEventListener('keypress', goHomeHandler)
  }, [])

  return (
    <div className="page-404" onClick={goHomeHandler}>
      <SEO title="404: Not found" />
      <div className="blok-404">
        <Typography size="large" component="h1" className="title-404">
          404
        </Typography>
        <Typography size="medium">Ooooops, Page not found!</Typography>
        <Typography className="info-404">
          Press Any Button or Screen to Go Home
        </Typography>
      </div>
    </div>
  )
}

export default NotFoundPage
